import React, { useEffect, useState } from 'react';
import Radium from 'radium';
import { Link } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import cn from 'classnames';
import _ from 'lodash';
import NumberFormat from 'react-number-format';
import { ToastProvider, useToasts } from 'react-toast-notifications';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { isAndAbove, isDeepEmpty, isValidEmail } from '../util';
import api from '../util/api';
import Button from '../components/button';
import Map from '../components/map';
import Footer from '../components/footer';

const TextInput = ({ id, name, value, label, autoComplete, placeholder, description, error, isRequired, onChange }) => {
  const inputName = name || id;
  return (
    <>
      <label htmlFor={id} className="block text-sm sm:text-base font-bold text-gray-700">
        {label}
        {isRequired && ' *'}
      </label>
      <input
        className={cn('mt-1 focus:ring-blue focus:border-blue block w-full shadow-sm sm:text-sm rounded-md', {
          'border-gray-300': !error,
          'border-red-700': error
        })}
        type="text"
        name={inputName}
        id={id}
        value={value}
        autoComplete={autoComplete}
        placeholder={placeholder}
        onChange={event => onChange(event.target.name, event.target.value)}
      />
      {error && <p className="mt-1 text-sm text-red-700">{error}</p>}
      {!error && description && <p className="mt-1 text-sm text-gray-500">{description}</p>}
    </>
  );
};

const MaskedInput = ({
  id,
  name,
  value,
  label,
  autoComplete,
  placeholder,
  description,
  error,
  isRequired,
  format,
  onChange
}) => {
  const inputName = name || id;
  return (
    <>
      <label htmlFor={id} className="block text-sm sm:text-base font-bold text-gray-700">
        {label}
        {isRequired && ' *'}
      </label>
      <NumberFormat
        id={id}
        name={inputName}
        value={value}
        className={cn('mt-1 focus:ring-blue focus:border-blue block w-full shadow-sm sm:text-sm rounded-md', {
          'border-gray-300': !error,
          'border-red-700': error
        })}
        autoComplete={autoComplete}
        placeholder={placeholder}
        format={format}
        onValueChange={values => onChange(inputName, values.formattedValue)}
      />
      {error && <p className="mt-1 text-sm text-red-700">{error}</p>}
      {!error && description && <p className="mt-1 text-sm text-gray-500">{description}</p>}
    </>
  );
};

const SelectInput = ({ id, name, value, label, autoComplete, description, options, onChange }) => {
  const inputName = name || id;
  return (
    <>
      <label htmlFor={id} className="block text-sm sm:text-base font-bold text-gray-700">
        {label}
      </label>
      <select
        id={id}
        name={inputName}
        value={value}
        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue focus:border-blue sm:text-sm"
        autoComplete={autoComplete}
        onChange={event => onChange(event.target.name, event.target.value)}>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {description && <p className="mt-1 text-sm text-gray-500">{description}</p>}
    </>
  );
};

const TextAreaInput = ({
  id,
  name,
  value,
  label,
  autoComplete,
  placeholder,
  description,
  error,
  isRequired,
  rows,
  onChange
}) => {
  const inputName = name || id;
  return (
    <>
      <label htmlFor={id} className="block text-sm sm:text-base font-bold text-gray-700">
        {label}
        {isRequired && ' *'}
      </label>
      <div className="mt-1">
        <textarea
          className={cn('shadow-sm focus:ring-blue focus:border-blue mt-1 block w-full sm:text-sm rounded-md', {
            'border-gray-300': !error,
            'border-red-700': error
          })}
          id={id}
          name={inputName}
          value={value}
          rows={rows || '3'}
          autoComplete={autoComplete}
          placeholder={placeholder}
          onChange={event => onChange(event.target.name, event.target.value)}
        />
      </div>
      {error && <p className="mt-1 text-sm text-red-700">{error}</p>}
      {!error && description && <p className="mt-1 text-sm text-gray-500">{description}</p>}
    </>
  );
};

const SubmitButton = ({ label, disabled, isLoading, ...props }) => {
  const isDisabled = disabled || isLoading;
  return (
    <button
      disabled={isDisabled}
      className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue hover:bg-blue-darker focus:outline-none disabled:opacity-50"
      {...props}>
      {isLoading && (
        <svg
          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      )}
      {label || 'Submit'}
    </button>
  );
};

const ContactIndex = props => {
  const breakpoints = useBreakpoint();
  const [openTab, setOpenTab] = useState(1);

  useEffect(() => {
    switch (props.location.hash) {
      case '#production':
        setOpenTab(1);
        break;
      case '#performance':
        setOpenTab(2);
        break;
      default:
        setOpenTab(1);
    }
  }, [props.location]);

  const paddingTop = isAndAbove('md', breakpoints) ? 86 : 66;

  return (
    <Layout location={props.location} breakpoints={breakpoints}>
      <SEO title="Contact" />
      <div className="bg-gray-900" style={{ paddingTop: paddingTop }}>
        <div className="m-auto py-6 sm:py-12 px-6" style={{ maxWidth: 1280 }}>
          <div className="max-w-4xl text-2xl sm:text-4xl text-blue-lighter font-extrabold tracking-wide">
            CONTACT US
          </div>
          <div className="text-base sm:text-lg text-gray-100">
            Please select the type of inquiry and fill out the form below.
          </div>
        </div>
      </div>
      <div className="">
        <div className="m-auto px-6" style={{ maxWidth: 1280 }}>
          <div className="flex flex-wrap">
            <div className="w-full">
              <ul className="flex mb-0 list-none flex-wrap pt-8 flex-row">
                <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                  <Link
                    to={'/contact#production'}
                    className={
                      'font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal ' +
                      (openTab === 1 ? 'text-white bg-blue' : 'text-blue bg-white')
                    }>
                    Production Inquiry
                  </Link>
                </li>
                <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                  <Link
                    to={'/contact#performance'}
                    className={
                      'font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal ' +
                      (openTab === 2 ? 'text-white bg-blue' : 'text-blue bg-white')
                    }>
                    Performance Inquiry
                  </Link>
                </li>
              </ul>
              <div className="relative flex flex-col w-full my-12">
                <div className={openTab !== 1 ? 'hidden' : ''}>
                  <ToastProvider>
                    <ProductionForm />
                  </ToastProvider>
                </div>
                <div className={openTab !== 2 ? 'hidden' : ''}>
                  <ToastProvider>
                    <PerformanceForm />
                  </ToastProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-900">
        <div className="m-auto px-6 pt-12 pb-8" style={{ maxWidth: 1280 }}>
          {openTab === 1 && (
            <div className="flex flex-col justify-center items-center max-w-xl text-center mx-auto pb-12">
              <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-white">
                Inquiring about hiring Zeo Music for a live performance or session musician booking?
              </p>
              <p className="mt-4 max-w-2xl text-xl text-gray-400 lg:mx-auto">
                Visit our Performance Inquiries page for more information.
              </p>
              <Link to={'/contact#performance'}>
                <Button className="mt-4" text="PERFORMANCE INQUIRES" />
              </Link>
            </div>
          )}
          {openTab === 2 && (
            <div className="flex flex-col justify-center items-center max-w-xl text-center mx-auto pb-12">
              <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-white">
                Inquiring about hiring Zeo Music LLC for your next studio project?
              </p>
              <p className="mt-4 max-w-2xl text-xl text-gray-400 lg:mx-auto">
                Visit our Production Inquiries page for more information.
              </p>
              <Link to={'/contact#production'}>
                <Button className="mt-4" text="PRODUCTION INQUIRES" />
              </Link>
            </div>
          )}
          <div className="flex-grow" style={{ minWidth: breakpoints.xs ? 250 : 450 }}>
            <Map height="350px" />
          </div>
        </div>
      </div>
      <Footer breakpoints={breakpoints} />
    </Layout>
  );
};

export default Radium(ContactIndex);

const PRODUCTION_DATA_INITIAL_STATE = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  preferredContact: 'email',
  numberOfSongs: '1/single',
  projectScope: '',
  services: [],
  budget: '',
  deadline: ''
};

const ProductionForm = () => {
  const { addToast } = useToasts();
  const [productionData, setProductionData] = useState(PRODUCTION_DATA_INITIAL_STATE);
  const [productionErrors, setProductionErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmitProduction = event => {
    event.preventDefault();
    if (!isFormValid()) return;

    setIsSubmitting(true);
    api
      .post('/production', productionData)
      .then(res => {
        setIsSubmitting(false);
        setProductionData(PRODUCTION_DATA_INITIAL_STATE);
        addToast(res.data?.message || 'Submitted successfully!', { appearance: 'success', autoDismiss: true });
        window.scrollTo(0, 0);
      })
      .catch(err => {
        setIsSubmitting(false);
        addToast(err?.data?.message || 'An error occurred submitting your production request.', {
          appearance: 'error',
          autoDismiss: true
        });
      });
  };

  const applyData = updatedData => {
    setProductionData(prev => ({ ...prev, ...updatedData }));
  };

  const applyError = updatedErrors => {
    setProductionErrors(prev => ({ ...prev, ...updatedErrors }));
  };

  const handleValueChange = (field, value) => {
    const newData = _.set(productionData, field, value);
    applyData(newData);
    const newErrors = _.set(productionErrors, field, '');
    applyError(newErrors);
  };

  const isFormValid = () => {
    const validate = {};
    if (!productionData.firstName) validate.firstName = 'First name is required';
    if (!productionData.lastName) validate.lastName = 'Last name is required';
    if (!productionData.email) validate.email = 'Email is required';
    else if (!isValidEmail(productionData.email)) validate.email = 'Must be a valid email format';
    if (!productionData.phone) validate.phone = 'Phone number is required';
    if (!productionData.budget)
      validate.budget = 'Project budget is required. Please write "unknown" if budget is unconfirmed.';

    applyError({ ...validate });

    return isDeepEmpty(validate);
  };

  const handleServicesChange = event => {
    const isChecked = event.target.checked;
    const value = event.target.value;

    setProductionData(prevState => {
      const data = { ...prevState };
      if (isChecked) {
        data.services = [...prevState.services, value];
      } else {
        data.services = prevState.services.filter(s => s !== value);
      }
      return data;
    });
  };

  return (
    <form onSubmit={handleSubmitProduction}>
      {/*PERSONAL CONTACT SECTION*/}
      <div className="mb-8">
        <div className="text-xl font-bold uppercase mb-8 pb-1 border-b-2 border-blue-darker text-blue-darker">
          Personal Contact
        </div>
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 sm:col-span-3">
            <TextInput
              id="firstName"
              value={productionData.firstName}
              label="First name"
              autoComplete="given-name"
              error={productionErrors.firstName}
              isRequired
              onChange={handleValueChange}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <TextInput
              id="lastName"
              value={productionData.lastName}
              label="Last name"
              autoComplete="family-name"
              error={productionErrors.lastName}
              isRequired
              onChange={handleValueChange}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <TextInput
              id="email"
              value={productionData.email}
              label="Email address"
              autoComplete="email"
              error={productionErrors.email}
              isRequired
              onChange={handleValueChange}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <MaskedInput
              id="phone"
              value={productionData.phone}
              label="Phone number"
              autoComplete="tel"
              error={productionErrors.phone}
              placeholder="eg. (555) 555-5555"
              format="(###) ###-####"
              isRequired
              onChange={handleValueChange}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <SelectInput
              id="preferredContact"
              value={productionData.preferredContact}
              label="Preferred Form of Contact"
              options={[
                { label: 'Email', value: 'email' },
                { label: 'Phone', value: 'phone' },
                { label: 'Either', value: 'either' }
              ]}
              onChange={handleValueChange}
            />
          </div>
        </div>
      </div>
      {/*PROJECT DETAILS SECTION*/}
      <div className="mb-8">
        <div className="text-xl font-bold uppercase mb-8 pb-1 border-b-2 border-blue-darker text-blue-darker">
          Project Details
        </div>
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 sm:col-span-3">
            <SelectInput
              id="numberOfSongs"
              value={productionData.numberOfSongs}
              label="How many songs?"
              options={[
                { label: '1 / Single', value: '1/single' },
                { label: '2', value: '2' },
                { label: '3', value: '3' },
                { label: '4', value: '4' },
                { label: '5', value: '5' },
                { label: '6', value: '6' },
                { label: '7', value: '7' },
                { label: '8', value: '8' },
                { label: '9', value: '9' },
                { label: '10', value: '10' },
                { label: '11', value: '11' },
                { label: '12', value: '12' },
                { label: '13', value: '13' },
                { label: '14', value: '14' },
                { label: '15+', value: '15+' }
              ]}
              onChange={handleValueChange}
            />
          </div>

          <div className="col-span-6">
            <TextAreaInput
              id="projectScope"
              value={productionData.projectScope}
              label="Scope of Project"
              rows="4"
              description="Please explain what you envision for your project. Describe your ideal instrumentation
                            requests and production desires."
              onChange={handleValueChange}
            />
          </div>

          <div className="col-span-6">
            <fieldset>
              <div>
                <legend className="font-bold text-sm sm:text-base text-gray-900">Services Desired</legend>
                <p className="text-sm text-gray-500">
                  Please select all services you'd like Zeo Music to provide for your project.
                </p>
              </div>
              <div className="mt-4 space-y-4">
                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      id="producer"
                      name="producer"
                      type="checkbox"
                      className="focus:ring-blue h-4 w-4 text-blue border-gray-300 rounded"
                      value="Producer"
                      checked={productionData.services.includes('Producer')}
                      onChange={handleServicesChange}
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label htmlFor="producer" className="font-bold">
                      PRODUCER
                    </label>
                    <p className="text-gray-500">
                      Providing input and helping to shape your song(s) to its best possible form
                    </p>
                  </div>
                </div>
                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      id="studio_engineer"
                      name="studio_engineer"
                      type="checkbox"
                      className="focus:ring-blue h-4 w-4 text-blue border-gray-300 rounded"
                      value="Studio Engineer"
                      checked={productionData.services.includes('Studio Engineer')}
                      onChange={handleServicesChange}
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label htmlFor="studio_engineer" className="font-bold">
                      STUDIO ENGINEER
                    </label>
                    <p className="text-gray-500">Capturing the best sound and "pressing record"</p>
                  </div>
                </div>
                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      id="session_musician"
                      name="session_musician"
                      type="checkbox"
                      className="focus:ring-blue h-4 w-4 text-blue border-gray-300 rounded"
                      value="Session Musician"
                      checked={productionData.services.includes('Session Musician')}
                      onChange={handleServicesChange}
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label htmlFor="session_musician" className="font-bold">
                      SESSION MUSICIAN
                    </label>
                    <p className="text-gray-500">
                      Can play most stringed instruments, piano, keyboards, drums and auxiliary percussion
                    </p>
                  </div>
                </div>
                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      id="mixing_engineer"
                      name="mixing_engineer"
                      type="checkbox"
                      className="focus:ring-blue h-4 w-4 text-blue border-gray-300 rounded"
                      value="Mixing Engineer"
                      checked={productionData.services.includes('Mixing Engineer')}
                      onChange={handleServicesChange}
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label htmlFor="mixing_engineer" className="font-bold">
                      MIXING ENGINEER
                    </label>
                    <p className="text-gray-500">
                      Work on an already recorded song to balance levels, effects, tones and dynamics
                    </p>
                  </div>
                </div>
                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      id="mastering_engineer"
                      name="mastering_engineer"
                      type="checkbox"
                      className="focus:ring-blue h-4 w-4 text-blue border-gray-300 rounded"
                      value="Mastering Engineer"
                      checked={productionData.services.includes('Mastering Engineer')}
                      onChange={handleServicesChange}
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label htmlFor="mastering_engineer" className="font-bold">
                      MASTERING ENGINEER
                    </label>
                    <p className="text-gray-500">
                      Final step to polish a final mix and prepares song(s) for CDs and digital distribution (IF ZEO
                      MUSIC IS PRODUCING YOUR SONG, THIS SERVICE IS TYPICALLY OUTSOURCED FOR $125/SONG)
                    </p>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>

          <div className="col-span-6 sm:col-span-3">
            <TextInput
              id="budget"
              value={productionData.budget}
              label="Project Budget"
              description={`Please write "unknown" if budget is unconfirmed.`}
              error={productionErrors.budget}
              isRequired
              onChange={handleValueChange}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <MaskedInput
              id="deadline"
              value={productionData.deadline}
              label="Deadline"
              placeholder="MM/DD/YYYY"
              format="##/##/####"
              description="When would you like your project to be available for purchase by?"
              onChange={handleValueChange}
            />
          </div>
        </div>
      </div>
      <div className="bg-gray-50 text-right">
        <SubmitButton type="submit" isLoading={isSubmitting} />
      </div>
    </form>
  );
};

const PERFORMANCE_DATA_INITIAL_STATE = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  eventCoordinatorFirstName: '',
  eventCoordinatorLastName: '',
  eventCoordinatorPhoneNumber: '',
  eventCoordinatorEmailAddress: '',
  venueName: '',
  venueAddress1: '',
  venueAddress2: '',
  venueCity: '',
  venueState: '',
  venueZip: '',
  venueCountry: '',
  eventDate: '',
  bandSize: '',
  bringSoundSystem: ''
};

const PerformanceForm = () => {
  const { addToast } = useToasts();
  const [performanceData, setPerformanceData] = useState(PERFORMANCE_DATA_INITIAL_STATE);
  const [performanceErrors, setPerformanceErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmitPerformance = event => {
    event.preventDefault();
    if (!isFormValid()) return;

    setIsSubmitting(true);
    api
      .post('/performance', performanceData)
      .then(res => {
        setIsSubmitting(false);
        setPerformanceData(PERFORMANCE_DATA_INITIAL_STATE);
        addToast(res.data?.message || 'Submitted successfully!', { appearance: 'success', autoDismiss: true });
        window.scrollTo(0, 0);
      })
      .catch(err => {
        setIsSubmitting(false);
        addToast(err.data?.message || 'An error occurred submitting your performance request.', {
          appearance: 'error',
          autoDismiss: true
        });
      });
  };

  const applyData = updatedData => {
    setPerformanceData(prev => ({ ...prev, ...updatedData }));
  };

  const applyError = updatedErrors => {
    setPerformanceErrors(prev => ({ ...prev, ...updatedErrors }));
  };

  const handleValueChange = (field, value) => {
    const newData = _.set(performanceData, field, value);
    applyData(newData);
    const newErrors = _.set(performanceErrors, field, '');
    applyError(newErrors);
  };

  const isFormValid = () => {
    const validate = {};
    if (!performanceData.firstName) validate.firstName = 'First name is required';
    if (!performanceData.lastName) validate.lastName = 'Last name is required';
    if (!performanceData.email) validate.email = 'Email is required';
    else if (!isValidEmail(performanceData.email)) validate.email = 'Must be a valid email format';
    if (!performanceData.phone) validate.phone = 'Phone number is required';

    applyError({ ...validate });

    return isDeepEmpty(validate);
  };

  const handleBringSoundSystemChange = event => {
    const value = event.target.value;

    setPerformanceData(prevState => {
      const data = { ...prevState };
      data.bringSoundSystem = value;
      return data;
    });
  };

  const handleBandSizeChange = event => {
    const value = event.target.value;

    setPerformanceData(prevState => {
      const data = { ...prevState };
      data.bandSize = value;
      return data;
    });
  };

  return (
    <form onSubmit={handleSubmitPerformance}>
      {/*EMPLOYER CONTACT SECTION*/}
      <div className="mb-8">
        <div className="text-xl font-bold uppercase mb-8 pb-1 border-b-2 border-blue-darker text-blue-darker">
          Employer Contact Information
        </div>
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 sm:col-span-3">
            <TextInput
              id="firstName"
              value={performanceData.firstName}
              label="First name"
              autoComplete="given-name"
              error={performanceErrors.firstName}
              isRequired
              onChange={handleValueChange}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <TextInput
              id="lastName"
              value={performanceData.lastName}
              label="Last name"
              autoComplete="family-name"
              error={performanceErrors.lastName}
              isRequired
              onChange={handleValueChange}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <TextInput
              id="email"
              value={performanceData.email}
              label="Email address"
              autoComplete="email"
              error={performanceErrors.email}
              isRequired
              onChange={handleValueChange}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <MaskedInput
              id="phone"
              value={performanceData.phone}
              label="Phone number"
              autoComplete="tel"
              error={performanceErrors.phone}
              isRequired
              placeholder="eg. (555) 555-5555"
              format="(###) ###-####"
              onChange={handleValueChange}
            />
          </div>
        </div>
      </div>
      {/*EVENT COORDINATOR CONTACT SECTION*/}
      <div className="mb-8">
        <div className="text-xl font-bold uppercase mb-8 pb-1 border-b-2 border-blue-darker text-blue-darker">
          Event Coordinator Contact Information
        </div>
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 sm:col-span-3">
            <TextInput
              id="eventCoordinatorFirstName"
              value={performanceData.eventCoordinatorFirstName}
              label="First name"
              autoComplete="given-name"
              onChange={handleValueChange}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <TextInput
              id="eventCoordinatorLastName"
              value={performanceData.eventCoordinatorLastName}
              label="Last name"
              autoComplete="family-name"
              onChange={handleValueChange}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <TextInput
              id="eventCoordinatorEmailAddress"
              value={performanceData.eventCoordinatorEmailAddress}
              label="Email address"
              autoComplete="email"
              onChange={handleValueChange}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <MaskedInput
              id="eventCoordinatorPhoneNumber"
              value={performanceData.eventCoordinatorPhoneNumber}
              label="Phone number"
              autoComplete="tel"
              placeholder="eg. (555) 555-5555"
              format="(###) ###-####"
              onChange={handleValueChange}
            />
          </div>
        </div>
      </div>
      {/*VENUE INFORMATION SECTION*/}
      <div className="mb-8">
        <div className="text-xl font-bold uppercase mb-8 pb-1 border-b-2 border-blue-darker text-blue-darker">
          Venue Information
        </div>
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6">
            <TextInput
              id="venueName"
              value={performanceData.venueName}
              label="Venue Name"
              onChange={handleValueChange}
            />
          </div>

          <div className="col-span-6">
            <TextInput
              id="venueAddress1"
              value={performanceData.venueAddress1}
              label="Address 1"
              autoComplete="address-line1"
              onChange={handleValueChange}
            />
          </div>

          <div className="col-span-6">
            <TextInput
              id="venueAddress2"
              value={performanceData.venueAddress2}
              label="Address 2"
              autoComplete="address-line2"
              onChange={handleValueChange}
            />
          </div>

          <div className="col-span-6 sm:col-span-4">
            <TextInput
              id="venueCity"
              value={performanceData.venueCity}
              label="City"
              autoComplete="address-level2"
              onChange={handleValueChange}
            />
          </div>

          <div className="col-span-6 sm:col-span-2">
            <TextInput
              id="venueState"
              value={performanceData.venueState}
              label="State/Province"
              autoComplete="address-level1"
              onChange={handleValueChange}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <TextInput
              id="venueZip"
              value={performanceData.venueZip}
              label="Zip/Postal Code"
              autoComplete="postal-code"
              onChange={handleValueChange}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <TextInput
              id="venueCountry"
              value={performanceData.venueCountry}
              label="Country"
              autoComplete="country"
              onChange={handleValueChange}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <MaskedInput
              id="eventDate"
              value={performanceData.eventDate}
              label="Date of Event"
              placeholder="MM/DD/YYYY"
              format="##/##/####"
              onChange={handleValueChange}
            />
          </div>
        </div>
      </div>
      {/*BAND/PERSONNEL SECTION*/}
      <div className="mb-8">
        <div className="text-xl font-bold uppercase mb-8 pb-1 border-b-2 border-blue-darker text-blue-darker">
          Band / Personnel Request
        </div>
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6">
            <fieldset>
              <div>
                <legend className="font-bold text-sm sm:text-base text-gray-900">Band Size</legend>
                <p className="text-sm text-gray-500">
                  Personnel / instrumentalists are subject to change and will be selected by the musician. Special
                  requests for musicians (either by name or by instrument) is not guaranteed.
                </p>
              </div>
              <div className="mt-4 space-y-4">
                <div className="flex items-center">
                  <input
                    id="solo"
                    name="solo"
                    type="radio"
                    className="focus:ring-blue h-4 w-4 text-blue border-gray-300"
                    value="Solo"
                    checked={performanceData.bandSize === 'Solo'}
                    onChange={handleBandSizeChange}
                  />
                  <label htmlFor="solo" className="ml-3 block text-sm font-bold text-gray-700">
                    Solo
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    id="acoustic_duo"
                    name="acoustic_duo"
                    type="radio"
                    className="focus:ring-blue h-4 w-4 text-blue border-gray-300"
                    value="Acoustic Duo"
                    checked={performanceData.bandSize === 'Acoustic Duo'}
                    onChange={handleBandSizeChange}
                  />
                  <label htmlFor="acoustic_duo" className="ml-3 block text-sm font-bold text-gray-700">
                    Acoustic Duo
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    id="trio"
                    name="trio"
                    type="radio"
                    className="focus:ring-blue h-4 w-4 text-blue border-gray-300"
                    value="Trio"
                    checked={performanceData.bandSize === 'Trio'}
                    onChange={handleBandSizeChange}
                  />
                  <label htmlFor="trio" className="ml-3 block text-sm font-bold text-gray-700">
                    Trio
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    id="full_band"
                    name="full_band"
                    type="radio"
                    className="focus:ring-blue h-4 w-4 text-blue border-gray-300"
                    value="Full Band"
                    checked={performanceData.bandSize === 'Full Band'}
                    onChange={handleBandSizeChange}
                  />
                  <label htmlFor="full_band" className="ml-3 block text-sm font-bold text-gray-700">
                    Full Band
                  </label>
                </div>
              </div>
            </fieldset>
          </div>
          <div className="col-span-6">
            <fieldset>
              <div>
                <legend className="font-bold text-sm sm:text-base text-gray-900">
                  Do the performers need to bring their own sound system?
                </legend>
              </div>
              <div className="mt-4 space-y-4">
                <div className="flex items-center">
                  <input
                    id="yes"
                    name="yes"
                    type="radio"
                    className="focus:ring-blue h-4 w-4 text-blue border-gray-300"
                    value="Yes"
                    checked={performanceData.bringSoundSystem === 'Yes'}
                    onChange={handleBringSoundSystemChange}
                  />
                  <label htmlFor="yes" className="ml-3 block text-sm font-bold text-gray-700">
                    Yes
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    id="no"
                    name="no"
                    type="radio"
                    className="focus:ring-blue h-4 w-4 text-blue border-gray-300"
                    value="No"
                    checked={performanceData.bringSoundSystem === 'No'}
                    onChange={handleBringSoundSystemChange}
                  />
                  <label htmlFor="no" className="ml-3 block text-sm font-bold text-gray-700">
                    No
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    id="unsure"
                    name="unsure"
                    type="radio"
                    className="focus:ring-blue h-4 w-4 text-blue border-gray-300"
                    value="Unsure"
                    checked={performanceData.bringSoundSystem === 'Unsure'}
                    onChange={handleBringSoundSystemChange}
                  />
                  <label htmlFor="unsure" className="ml-3 block text-sm font-bold text-gray-700">
                    Unsure
                  </label>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
      <div className="bg-gray-50 text-right">
        <SubmitButton type="submit" isLoading={isSubmitting} />
      </div>
    </form>
  );
};
