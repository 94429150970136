import axios from 'axios';

const http = axios.create({
  baseURL: process.env.GATSBY_API_URL
});

http.interceptors.response.use(
  response => response,
  error => {
    return Promise.reject(error.response);
  }
);

export default http;
